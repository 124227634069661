import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiscord,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';

export type Props = {
  className?: string;
};

const SocialLinks = (props: Props) => (
  <section className={props.className}>
    {Object.entries(GlobalClientConfig.socialLinks).map(([key, value]) => {
      switch (key) {
        case 'facebook':
          return (
            <a href={value} target='blank' className={'btn btn-dark'} key={key}>
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={'social-icon facebook rtl-maintain-orientation'}
              />
            </a>
          );
        case 'twitter':
          return (
            <a href={value} target='blank' className={'btn btn-dark'} key={key}>
              <FontAwesomeIcon
                icon={faTwitter}
                className={'social-icon twitter rtl-maintain-orientation'}
              />
            </a>
          );
        case 'youtube':
          return (
            <a href={value} target='blank' className={'btn btn-dark'} key={key}>
              <FontAwesomeIcon
                icon={faYoutube}
                className={'social-icon youtube rtl-maintain-orientation'}
              />
            </a>
          );
        case 'instagram':
          return (
            <a href={value} target='blank' className={'btn btn-dark'} key={key}>
              <FontAwesomeIcon
                icon={faInstagram}
                className={'social-icon instagram rtl-maintain-orientation'}
              />
            </a>
          );
        case 'discord':
          return (
            <a href={value} target='blank' className={'btn btn-dark'} key={key}>
              <FontAwesomeIcon
                icon={faDiscord}
                className={'social-icon discord rtl-maintain-orientation'}
              />
            </a>
          );
        case 'email':
          return (
            <a href={value} target='blank' className={'btn btn-dark'} key={key}>
              <FontAwesomeIcon icon={faEnvelope} className={'social-icon'} />
            </a>
          );
        default:
          break;
      }
      return <></>;
    })}
  </section>
);

export default SocialLinks;
