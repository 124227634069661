import DefaultBackground from 'HiveClient/images/default-background.jpg';

export interface IClientBackgrounds {
  defaultBackground: string;
  homeFooterBackground: string;
  storeBackground: string;
  authCarouselBackground: string;
}

const clientBackgrounds: IClientBackgrounds = {
  defaultBackground: DefaultBackground,
  homeFooterBackground: DefaultBackground,
  authCarouselBackground: DefaultBackground,
  storeBackground: DefaultBackground,
};

export default clientBackgrounds;
