import Navbar from 'react-bootstrap/Navbar';

import styles from './Footer.module.scss';

import SocialLinks from 'HiveClient/components/FooterContent/SocialLinks';
import ContactLinks from 'HiveClient/components/FooterContent/ContactLinks';
import LearnLinks from 'HiveClient/components/FooterContent/LearnLinks';
import Branding from 'HiveClient/components/FooterContent/Branding';
import Container from 'react-bootstrap/Container';
import { useFeatureFlagsContext } from '../../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';

export type Props = {
  preventNavigation: boolean;
};

const Footer = (props: Props) => {
  const { checkFeatureFlags } = useFeatureFlagsContext();

  return (
    <Navbar as={'footer'} variant={'dark'} bg={'dark'}>
      <Container fluid>
        <section
          className={[
            'd-flex',
            'flex-column',
            'flex-column-reverse',
            'd-md-flex',
            'flex-lg-row',
            'align-items-center',
            'justify-content-around',
            'py-4',
            styles.footer,
          ].join(' ')}
        >
          <section
            className={[
              'd-flex flex-column flex-lg-row',
              styles.mediaSection,
            ].join(' ')}
          >
            {!props.preventNavigation && (
              <SocialLinks className={styles.mediaLinks} />
            )}
            {checkFeatureFlags(FeaturesEnum.FooterIcons) && (
              <>
                <section className={'mx-3 mb-3'}>
                  {!props.preventNavigation && (
                    <LearnLinks
                      className={[
                        'my-3 my-lg-0 mx-auto mx-lg-0',
                        styles.footerNav,
                      ].join(' ')}
                    />
                  )}
                </section>
                <section className={'mx-3 mb-3'}>
                  {!props.preventNavigation && (
                    <ContactLinks className={styles.footerNav} />
                  )}
                </section>
              </>
            )}
          </section>
          <Branding className={styles.brandSection} />
        </section>
      </Container>
    </Navbar>
  );
};

export default Footer;
