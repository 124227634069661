import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import Logo from 'HiveClient/images/logo-footer.png';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useFeatureFlagsContext } from '../../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';

export type Props = {
  className?: string;
};

const Branding = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();
  const { checkFeatureFlags } = useFeatureFlagsContext();

  return (
    <section className={props.className}>
      {checkFeatureFlags(FeaturesEnum.FooterIcons) && (
        <img
          style={{ maxWidth: '208px' }}
          className={['mx-auto', 'mx-lg-0', 'mt-3', 'mb-1'].join(' ')}
          src={Logo}
          alt={GlobalClientConfig.clientName}
        />
      )}
      <small className={'text-dark text-center text-lg-end m-auto m-lg-0'}>
        {t('footer.copyright', {
          year: DateTime.local().year,
          copyrightName: GlobalClientConfig.copyrightName,
        })}
      </small>
    </section>
  );
};

export default Branding;
