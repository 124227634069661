import { useTranslation } from 'react-i18next';

export type Props = {
  className?: string;
};

const LearnLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <nav className={props.className}>
      <h6>{t('footer.learn-more.heading', 'Learn More')}</h6>
      <a href={'/'} target='blank' data-semantic={'footer-learn-more-link'}>
        {t('footer.learn-more.about-link-label', 'About')}
      </a>
      <a href={'/'} target='blank' data-semantic={'footer-services-link'}>
        {t('footer.learn-more.services-link-label', 'Services')}
      </a>
      <a
        href={'https://swarmio.media/terms.html'}
        target='blank'
        data-semantic={'footer-tos-link'}
      >
        {t('footer.learn-more.tos-link-label')}
      </a>
      <a
        href={'https://swarmio.media/privacy.html'}
        target='blank'
        data-semantic={'footer-privacy-policy-link'}
      >
        {t('footer.learn-more.privacy-policy-link-label')}
      </a>
    </nav>
  );
};

export default LearnLinks;
