import { useTranslation } from 'react-i18next';

export type Props = {
  className?: string;
};

const ContactLinks = (props: Props) => {
  // purposefully leaving namespace blank as component is used on multiple pages
  const { t } = useTranslation();

  return (
    <nav className={props.className}>
      <h6>{t('footer.contact-us.heading', 'Contact Us')}</h6>
      <a
        href={'/'}
        target='blank'
        data-semantic={'footer-buisness-inquriries-link'}
      >
        {t(
          'footer.contact-us.business-inquiries-link-label',
          'Business Inquiries'
        )}
      </a>
      <a href={'/'} target='blank' data-semantic={'footer-player-support-link'}>
        {t(
          'footer.contact-us.org-and-player-support-link-label',
          'Organizer and Player Support'
        )}
      </a>
      <a href={'/'} target='blank' data-semantic={'footer-help-center-link'}>
        {t('footer.contact-us.help-center-link-label', 'Help Center')}
      </a>
      <a href={'/'} target='blank' data-semantic={'footer-brand-link'}>
        {t('footer.contact-us.brand-assets-link-label', 'Brand Assets')}
      </a>
    </nav>
  );
};

export default ContactLinks;
